import { faClock, faLocationDot } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import DateTimeLocalized from '@/shared/components/DateTimeLocalized';
import { AgendaItem } from '@/shared/types/models';

interface Props {
  start_time: AgendaItem['start_time'];
  end_time: AgendaItem['end_time'];
  agenda_category: AgendaItem['agenda_category'];
  textSize?: string;
}

function AgendaItemMeta({
  start_time,
  end_time,
  agenda_category,
  textSize = '',
}: Props) {
  return (
    <>
      <div className={`text-neutral-70 ${textSize}`}>
        <FontAwesomeIcon icon={faClock} className="pe-2" fixedWidth />
        <DateTimeLocalized format="HH:mm">{start_time}</DateTimeLocalized>
        {start_time !== end_time && (
          <>
            <span className="px-1">-</span>
            <DateTimeLocalized format="HH:mm">{end_time}</DateTimeLocalized>
          </>
        )}
      </div>

      <div className={`text-neutral-70 ${textSize}`}>
        <FontAwesomeIcon
          icon={faLocationDot}
          className="text-neutral-70 pe-2"
          fixedWidth
        />
        {agenda_category.name}
      </div>
    </>
  );
}

export default AgendaItemMeta;
